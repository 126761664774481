import { FC, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { ParagraphLG, HeadingSM, TitleMD, ParagraphSM, TitleLG } from '@/atoms/Text'
import { useTheatricalRegionContext } from '@/contexts/TheatricalRegionContext/TheatricalRegionContext'
import { useIsDarkMode } from '@/contexts/ThemeContext'
import { ProjectFundingGoalDisplayV3Placeholder } from '@/organisms/ProjectFundingGoalDisplay/ProjectFundingGoalDisplayV3Placeholder'
import { ProjectFundingInfoModal } from '@/organisms/ProjectFundingGoalDisplay/ProjectFundingInfoModal'
import { UseGoalValues } from '@/services/GoalService/GoalService'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { TicketsInfoIconTooltip } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsInfoIconTooltip'
import { ProjectFundingTheatricalText } from './ProjectFundingTheatricalText'

interface GoalDisplayProps extends UseGoalValues {
  fullWidth?: boolean
  slug: string
}

const estimatedBackerKey = 'estimatedBacker'
const estimatedTicketsSoldKey = 'estimatedTicketsSold'
const lastTicketsGiftedKey = 'lastTicketsGifted'

function returnIsTheatrical(slug: string) {
  if (slug === 'cabrini') {
    return true
  } else {
    return false
  }
}

function returnGoal(goal: number) {
  return goal ?? 2000000
}

function returnCost(cost: number) {
  return cost ?? 14
}

function returnNumGifted(raisedRaw: number | null | undefined, costPerTicket: number) {
  if (raisedRaw) {
    return Math.round(raisedRaw / 100 / costPerTicket)
  }
  return 0
}

function returnMilestoneProgress(
  isTheatrical: boolean,
  numTicketsGifted: number,
  goal: number,
  percentProgress: number | null | undefined,
  estimatedTicketsSold: number,
): number {
  if (isTheatrical) {
    return Math.round(((numTicketsGifted + estimatedTicketsSold) / goal) * 100)
  }
  return percentProgress ?? 0
}

export const ProjectFundingGoalDisplayV4: FC<GoalDisplayProps> = ({
  fullWidth = false,
  cost,
  loading,
  error,
  raisedRaw,
  slug,
  percentProgress,
  raised,
  backers,
  groupName,
  refetch,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { t } = useTranslate('pif')
  const isDarkMode = useIsDarkMode()
  const { locale } = useLocale()
  const { goalLineInfo } = useTheatricalRegionContext()
  const [, setEstimatedBackers] = useState(0)
  const estimatedBackersRef = useRef<number>(0)
  const [estimatedTicketsSold, setEstimatedTicketsSold] = useState(0)
  const estimatedTicketsSoldRef = useRef<number>(0)

  const isTheatrical = returnIsTheatrical(slug)
  const goal = returnGoal(goalLineInfo?.ticketGoal)
  const costPerTicket = returnCost(goalLineInfo?.costPerTicket)
  const lastRealtimeUpdateRef = useRef<number>(Date.now())
  const numTicketsGifted = returnNumGifted(raisedRaw, costPerTicket)
  const milestoneProgress = returnMilestoneProgress(
    isTheatrical,
    numTicketsGifted,
    goal,
    percentProgress,
    estimatedTicketsSold,
  )

  useEffect(() => {
    const estBackers = Number(localStorage.getItem(estimatedBackerKey)) ?? 0
    const estTickets = Number(localStorage.getItem(estimatedTicketsSoldKey)) ?? 0

    const lastNumTicketsGifted = Number(localStorage.getItem(lastTicketsGiftedKey) ?? 0)

    if (lastNumTicketsGifted !== numTicketsGifted) {
      setEstimatedBackers(0)
      estimatedBackersRef.current = 0
      setEstimatedTicketsSold(0)
      estimatedTicketsSoldRef.current = 0
      localStorage.setItem(estimatedBackerKey, '0')
      localStorage.setItem(estimatedTicketsSoldKey, '0')
      localStorage.setItem(lastTicketsGiftedKey, String(numTicketsGifted))
    } else {
      setEstimatedBackers(estBackers)
      estimatedBackersRef.current = estBackers
      setEstimatedTicketsSold(estTickets)
      estimatedTicketsSoldRef.current = estTickets
      localStorage.setItem(lastTicketsGiftedKey, String(numTicketsGifted))
    }
  }, [numTicketsGifted])

  useEffect(() => {
    const interval = setInterval(() => {
      estimatedTicketsSoldRef.current = Math.ceil(estimatedTicketsSoldRef.current + 1)
      estimatedBackersRef.current = estimatedBackersRef.current + 1
      setEstimatedTicketsSold(estimatedTicketsSoldRef.current)
      setEstimatedBackers(estimatedBackersRef.current)
      localStorage.setItem(estimatedTicketsSoldKey, String(estimatedTicketsSoldRef.current))
      localStorage.setItem(estimatedBackerKey, String(estimatedBackersRef.current))
      lastRealtimeUpdateRef.current = Date.now()
      if (estimatedTicketsSoldRef.current % 50 === 0) {
        refetch?.()
      }
    }, 25000)
    return () => clearInterval(interval)
  })

  if (!cost || loading || error) {
    return <ProjectFundingGoalDisplayV3Placeholder fullWidth={fullWidth} />
  }

  const headerTextColor = isDarkMode ? 'white' : 'core-gray-950'
  const paragraphTextColor = isDarkMode ? 'core-gray-300' : 'core-gray-500'

  const fullWidthClass = !fullWidth ? '' : 'lg:flex-row lg:gap-14'

  return (
    <div className="flex flex-col lg:gap-6">
      <div className="flex w-full flex-row justify-between">
        {isTheatrical && (
          <div
            className={classNames(
              'mg:mb-0 mb-3 flex w-full items-center justify-between gap-3 md:flex-row md:gap-0 flex-row',
            )}
          >
            <div className="flex items-center justify-between">
              <TitleLG color={isDarkMode ? 'white' : 'gray-950'} weight="bold" className="mr-2 flex justify-between">
                {goalLineInfo?.goalTitle}
              </TitleLG>
            </div>
            <TicketsInfoIconTooltip iconSize={20} isDark={false} />
          </div>
        )}
        {!isTheatrical && (
          <div className="flex flex-col gap-2">
            <HeadingSM className="hidden lg:block" color={headerTextColor} weight="bold">
              {t('totalGoal', 'Total Goal')}
            </HeadingSM>
            <TitleMD className="block lg:hidden" color={headerTextColor} weight="semibold">
              {t('totalBoxOffice', 'Total Box Office')}
            </TitleMD>
          </div>
        )}
        {!isTheatrical && (
          <div className="flex flex-col justify-end">
            <ParagraphLG className="hidden lg:block" color={headerTextColor}>
              {percentProgress ? new Intl.NumberFormat(locale).format(percentProgress) : '< 1'}%
            </ParagraphLG>
            <ParagraphSM className="block lg:hidden" color={headerTextColor}>
              {percentProgress ? new Intl.NumberFormat(locale).format(percentProgress) : '< 1'}%
            </ParagraphSM>
          </div>
        )}
      </div>
      <div className={classNames('flex flex-col gap-1', fullWidthClass)}>
        <div className="flex flex-row justify-between lg:hidden">
          <div className="flex flex-col gap-1 lg:gap-2">
            <ParagraphSM color={headerTextColor} className="block lg:hidden" weight="semibold">
              {isTheatrical
                ? t('numberOfTickets', '{{numTickets}} Tickets', {
                    numTickets: new Intl.NumberFormat(locale).format(numTicketsGifted + estimatedTicketsSold),
                  })
                : raised}
            </ParagraphSM>
          </div>
          <div className="flex flex-col gap-1 lg:gap-2">
            <ParagraphSM color={paragraphTextColor} className="block lg:hidden">
              {isTheatrical ? (
                <>
                  {new Intl.NumberFormat(locale).format(goal)} {t('totalTicketsGoalV6', 'Goal')}
                </>
              ) : (
                t('ofCostGoal', 'of {{cost}} goal', { cost })
              )}
            </ParagraphSM>
          </div>
        </div>
        <div className="flex basis-1/2 flex-col gap-2 lg:gap-6">
          <div className={classNames('w-full rounded-full', isDarkMode ? 'bg-core-gray-800' : 'bg-core-gray-200')}>
            <div
              className="h-2.5 rounded-full bg-oxide-700"
              style={{ width: `${milestoneProgress > 100 ? '100%' : milestoneProgress ?? 0}%` }}
            />
          </div>
          <div className="flex flex-row justify-between">
            <div className="hidden flex-col gap-1 lg:flex lg:gap-2">
              <TitleLG color={headerTextColor} className="hidden text-xl lg:block xl:text-2xl" weight="semibold">
                {isTheatrical
                  ? t('numberOfTickets', '{{numTickets}} Tickets', {
                      numTickets: new Intl.NumberFormat(locale).format(numTicketsGifted + estimatedTicketsSold),
                    })
                  : raised}
              </TitleLG>
              <div className="flex flex-row items-center">
                <ParagraphLG color={paragraphTextColor} className="hidden text-base lg:block xl:text-lg">
                  {isTheatrical
                    ? t('ofTicketsGoalV6', 'of {{numTickets}} Goal', {
                        numTickets: new Intl.NumberFormat(locale).format(goal),
                      })
                    : t('ofCostGoal', 'of {{cost}} goal', { cost })}
                </ParagraphLG>
              </div>
            </div>
            <ProjectFundingTheatricalText
              headerTextColor={headerTextColor}
              milestoneProgress={milestoneProgress}
              paragraphTextColor={paragraphTextColor}
              setIsModalOpen={setIsModalOpen}
              isTheatrical={isTheatrical}
              backers={backers}
            />
          </div>
        </div>
        <ProjectFundingInfoModal
          onClose={() => setIsModalOpen(false)}
          show={isModalOpen}
          goalName={`${groupName}`}
          isTheatrical={isTheatrical}
        />
      </div>
    </div>
  )
}
