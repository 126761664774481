import { FC } from 'react'
import classNames from 'classnames'
import { Placeholder } from '@/atoms/Placeholder'
import { AsLabel, TitleSM } from '@/atoms/Text'
import { ParagraphXS } from '@/atoms/Typography'
import { useIsDarkMode } from '@/contexts/ThemeContext'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

export const ProjectFundingGoalDisplayPlaceholder: FC<{
  fullWidth?: boolean
}> = ({ fullWidth }) => {
  const isDarkMode = useIsDarkMode()
  const { t } = useTranslate('common')

  const fullWidthClass = !fullWidth ? '' : 'lg:flex-row lg:gap-14'
  const placeholderBackgroundClass = isDarkMode ? '!bg-gray-600' : '!bg-core-gray-950'
  const paragraphTextColorClass = isDarkMode ? 'text-gray-300' : 'text-gray-600'
  const headerTextColorClass = isDarkMode ? 'white' : 'core-gray-950'

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-2">
        <ParagraphXS color={paragraphTextColorClass} className="uppercase">
          <Translate i18nKey="totalRaised" t={t}>
            Total Raised
          </Translate>
        </ParagraphXS>
        <Placeholder className="mt-1 h-10 w-44 rounded-full" placeholderClassName={placeholderBackgroundClass} />
      </div>
      <div className={classNames('flex flex-col gap-10', fullWidthClass)}>
        <div className="flex basis-1/2 flex-col gap-4">
          <div className="flex flex-row items-center justify-between">
            <ParagraphXS color={paragraphTextColorClass} className="uppercase">
              <Translate i18nKey="progress" t={t}>
                Progress
              </Translate>
            </ParagraphXS>
            <Placeholder className="h-4 w-32 rounded-full" placeholderClassName={placeholderBackgroundClass} />
          </div>
          <Placeholder className="h-4 rounded-full" placeholderClassName={placeholderBackgroundClass} />
          <div className="flex flex-row justify-between">
            <div className="flex basis-1/2 flex-col gap-2">
              <ParagraphXS color={paragraphTextColorClass} className="uppercase">
                <Translate i18nKey="backers" t={t}>
                  Backers
                </Translate>
              </ParagraphXS>
              <Placeholder className="h-8 w-32 rounded-full" placeholderClassName={placeholderBackgroundClass} />
            </div>
            <div className="flex basis-1/2 flex-col gap-2">
              <ParagraphXS color={paragraphTextColorClass} className="text-right uppercase">
                <Translate i18nKey="goal" t={t}>
                  Goal
                </Translate>
              </ParagraphXS>
              <Placeholder className="h-8 w-32 rounded-full" placeholderClassName={placeholderBackgroundClass} />
            </div>
          </div>
        </div>

        <div className="flex basis-1/2 flex-col gap-4">
          <TitleSM weight="bold" as={AsLabel} color={headerTextColorClass}>
            {t('whatThisMoneyGoesToward', 'What This Money Goes Toward')}
          </TitleSM>
          <div className="mb-6 flex flex-col gap-2">
            <Placeholder className="h-4 w-full rounded-full" placeholderClassName={placeholderBackgroundClass} />
            <Placeholder className="h-4 w-full rounded-full" placeholderClassName={placeholderBackgroundClass} />
            <Placeholder className="h-4 w-2/3 rounded-full" placeholderClassName={placeholderBackgroundClass} />
          </div>
        </div>
      </div>
    </div>
  )
}
