import React, { FC, useMemo } from 'react'
import classNames from 'classnames'
import { ContentfulRichText } from '@/atoms/ContentfulRichText'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { QuestionMarkFilled } from '@/atoms/Icons/QuestionMarkFilled'
import { TitleLG, ParagraphLG } from '@/atoms/Text'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { Modal } from '@/molecules/Modal'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface ProjectFundingInfoModalProps {
  goalName: string
  show: boolean
  isTheatrical?: boolean
  onClose: () => void
}

export const ProjectFundingInfoModal: FC<ProjectFundingInfoModalProps> = ({
  onClose,
  show,
  goalName,
  isTheatrical,
}) => {
  const { pifFundingInfoModal } = useProjectMeta()
  const { t } = useTranslate('pif')

  const infoText = useMemo(() => {
    if (pifFundingInfoModal) {
      return <ContentfulRichText className="text-core-gray-700" json={pifFundingInfoModal?.json} />
    } else if (isTheatrical) {
      return (
        <ParagraphLG color="core-gray-700">
          <Translate t={t} i18nKey="pifInfoModalText">
            This number is an estimated real-time total produced from theatrical ticket and Pay it Forward sales. Actual
            numbers may be adjusted after auditing.
          </Translate>
        </ParagraphLG>
      )
    } else {
      return (
        <ParagraphLG color="core-gray-700">
          <Translate t={t} i18nKey="pifInfoModalGoalText">
            This number is an estimate and actual numbers may be adjusted after auditing. It is an estimated real-time
            total revenue for the {{ goalName }} Pay it Forward goal. Revenue pays for all aspects of the show including
            production (roughly 33%), distribution, marketing, merchandise, operations, and this awesome website.
          </Translate>
        </ParagraphLG>
      )
    }
  }, [pifFundingInfoModal, isTheatrical, goalName, t])

  return (
    <div className="px-20">
      <Modal panelClasses="!max-w-[300px]" className={classNames('mx-auto z-[10000]')} isOpen={show} onClose={onClose}>
        <div className="absolute right-5 top-5 cursor-pointer" onClick={onClose}>
          <CloseIcon size={18} color="core-gray-700" />
        </div>
        <div className="mb-2 flex items-center justify-start">
          <QuestionMarkFilled color1={'green'} color2={'white'} size={32} />
          <TitleLG className="pl-2" weight="bold">
            Info
          </TitleLG>
        </div>
        <div className="overflow-y-auto">{infoText}</div>
      </Modal>
    </div>
  )
}
