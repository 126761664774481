import { FC } from 'react'
import classNames from 'classnames'
import { QuestionMarkFilled } from '@/atoms/Icons/QuestionMarkFilled'
import { Placeholder } from '@/atoms/Placeholder'
import { HeadingSM, ParagraphLG, TitleMD, ParagraphSM, AsDiv } from '@/atoms/Text'
import { slugs } from '@/constants'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { useIsDarkMode } from '@/contexts/ThemeContext'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

export const ProjectFundingGoalDisplayV3Placeholder: FC<{
  fullWidth?: boolean
}> = ({ fullWidth }) => {
  const isDarkMode = useIsDarkMode()
  const { t } = useTranslate('pif')
  const { slug } = useProjectMeta()

  const headerTextColorClass = isDarkMode ? 'white' : 'core-gray-950'
  const paragraphTextColorClass = isDarkMode ? 'core-gray-300' : 'core-gray-500'
  const placeholderBackgroundClass = isDarkMode ? 'bg-core-gray-600' : 'bg-core-gray-950'

  const fullWidthClass = !fullWidth ? '' : 'lg:flex-row lg:gap-14'
  const isTheatrical = slug === slugs.soundOfFreedom || slug === slugs.hisOnlySon

  return (
    <div className="flex flex-col gap-4 lg:gap-6">
      <div className="flex flex-row justify-between">
        <>
          {isTheatrical ? (
            <div className="flex flex-col gap-2">
              <HeadingSM color={headerTextColorClass} className="hidden lg:block" weight="bold">
                <Translate t={t} i18nKey="ticketSales">
                  Ticket Sales
                </Translate>
              </HeadingSM>
              <TitleMD color={headerTextColorClass} className="block lg:hidden " weight="semibold">
                <Translate t={t} i18nKey="ticketSales">
                  Ticket Sales
                </Translate>
              </TitleMD>
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <HeadingSM color={headerTextColorClass} className="hidden lg:block" weight="bold">
                <Translate t={t} i18nKey="totalRaised">
                  Total Raised
                </Translate>
              </HeadingSM>
              <TitleMD color={headerTextColorClass} className="block lg:hidden" weight="semibold">
                <Translate t={t} i18nKey="totalRaised">
                  Total Raised
                </Translate>
              </TitleMD>
            </div>
          )}
        </>
        <div className="flex flex-col justify-end">
          <ParagraphLG as={AsDiv} className="hidden lg:flex lg:flex-row lg:items-center">
            <Placeholder className="h-6 w-6 rounded-full" placeholderClassName={placeholderBackgroundClass} />
            <span>%</span>
          </ParagraphLG>
          <ParagraphSM as={AsDiv} className="flex flex-row items-center lg:hidden">
            <Placeholder className="h-4 w-4 rounded-full" placeholderClassName={placeholderBackgroundClass} />
            <span>%</span>
          </ParagraphSM>
        </div>
      </div>
      <div className={classNames('flex flex-col gap-1', fullWidthClass)}>
        <div className="flex basis-1/2 flex-col gap-6 ">
          <div className={classNames('w-full rounded-full', isDarkMode ? 'bg-core-gray-800' : 'bg-core-gray-200')}>
            <Placeholder className="h-2 w-44 rounded-full" placeholderClassName={placeholderBackgroundClass} />
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-2">
              <Placeholder
                className="h-6 w-28 rounded-full lg:h-8 lg:w-32"
                placeholderClassName={placeholderBackgroundClass}
              />
              <div className="flex flex-row items-center">
                <Placeholder
                  className="h-6 w-36 rounded-full lg:h-8 lg:w-44"
                  placeholderClassName={placeholderBackgroundClass}
                />
                <div className="pl-1.5">
                  <QuestionMarkFilled color1={'core-gray-500'} color2={'white'} className="h-4 cursor-help lg:h-5" />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Placeholder
                className="w-18 h-6 rounded-full lg:h-8 lg:w-20"
                placeholderClassName={placeholderBackgroundClass}
              />
              <ParagraphLG color={paragraphTextColorClass} className="hidden lg:flex lg:justify-end">
                {t('people', 'People')}
              </ParagraphLG>
              <ParagraphSM color={paragraphTextColorClass} className="flex justify-end lg:hidden">
                {t('people', 'People')}
              </ParagraphSM>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
