import { FC, useState } from 'react'
import classNames from 'classnames'
import { ParagraphLG, TitleMD, ParagraphSM, TitleLG, EyebrowMD } from '@/atoms/Text'
import { useTheatricalRegionContext } from '@/contexts/TheatricalRegionContext/TheatricalRegionContext'
import { useIsDarkMode } from '@/contexts/ThemeContext'
import { ProjectFundingGoalDisplayV3Placeholder } from '@/organisms/ProjectFundingGoalDisplay/ProjectFundingGoalDisplayV3Placeholder'
import { UseGoalValues } from '@/services/GoalService/GoalService'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate, Translate } from '@/utils/translate/translate-client'
import { TicketsInfoIconTooltip } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsInfoIconTooltip'

interface GoalDisplayProps extends UseGoalValues {
  fullWidth?: boolean
  slug: string
}

function returnIsTheatrical(slug: string) {
  if (slug === 'cabrini') {
    return true
  } else {
    return false
  }
}

function returnGoal(goal: number) {
  return goal ?? 2000000
}

function returnCost(cost: number) {
  return cost ?? 14
}

function returnNumGifted(raisedRaw: number | null | undefined, costPerTicket: number) {
  if (raisedRaw) {
    return Math.round(raisedRaw / 100 / costPerTicket)
  }
  return 0
}

function returnMilestoneProgress(
  isTheatrical: boolean,
  numTicketsGifted: number,
  goal: number,
  percentProgress: number | null | undefined,
  estimatedTicketsSold: number,
): number {
  if (isTheatrical) {
    return Math.round(((numTicketsGifted + estimatedTicketsSold) / goal) * 100)
  }
  return percentProgress ?? 0
}

export const ProjectFundingGoalDisplayV5: FC<GoalDisplayProps> = ({
  fullWidth = false,
  cost,
  loading,
  error,
  raisedRaw,
  slug,
  percentProgress,
  costRaw,
}) => {
  const { t } = useTranslate('pif')
  const isDarkMode = useIsDarkMode()
  const { locale } = useLocale()
  const { goalLineInfo } = useTheatricalRegionContext()
  const [estimatedTicketsSold] = useState(0)

  const isTheatrical = returnIsTheatrical(slug)
  const goal = returnGoal(goalLineInfo?.ticketGoal)
  const costPerTicket = returnCost(goalLineInfo?.costPerTicket)
  const numTicketsGifted = returnNumGifted(raisedRaw, costPerTicket)
  const milestoneProgress = returnMilestoneProgress(
    isTheatrical,
    numTicketsGifted,
    goal,
    percentProgress,
    estimatedTicketsSold,
  )

  if (!cost || loading || error) {
    return <ProjectFundingGoalDisplayV3Placeholder fullWidth={fullWidth} />
  }

  const headerTextColor = isDarkMode ? 'white' : 'core-gray-950'
  const paragraphTextColor = isDarkMode ? 'core-gray-300' : 'core-gray-500'

  const fullWidthClass = !fullWidth ? '' : 'lg:flex-row lg:gap-14'

  const views = new Intl.NumberFormat(locale).format(Number(raisedRaw))
  const viewsGoal = new Intl.NumberFormat(locale).format(Number(costRaw))

  return (
    <div className="flex flex-col lg:gap-6">
      <div className="flex w-full flex-row justify-between">
        {isTheatrical && (
          <div
            className={classNames(
              'mg:mb-0 mb-3 flex w-full items-center justify-between gap-3 md:flex-row md:gap-0 flex-row',
            )}
          >
            <div className="flex items-center justify-between">
              <TitleLG color={isDarkMode ? 'white' : 'gray-950'} weight="bold" className="mr-2 flex justify-between">
                {goalLineInfo?.goalTitle}
              </TitleLG>
            </div>
            <TicketsInfoIconTooltip iconSize={20} isDark={false} />
          </div>
        )}
        {!isTheatrical && (
          <div className="flex flex-col gap-2">
            <TitleMD className="hidden lg:block" color={headerTextColor} weight="bold">
              <Translate i18nKey="reachWorldWithSoundOfFreedom" t={t}>
                Reach the world with the <i>Sound of Freedom</i>
              </Translate>
            </TitleMD>
            <EyebrowMD
              className="md:photon-title-xxs block tracking-tight md:!font-bold lg:hidden"
              color={headerTextColor}
              weight="semibold"
            >
              <Translate i18nKey="reachWorldWithSoundOfFreedom" t={t}>
                Reach the world with the <i>Sound of Freedom</i>
              </Translate>
            </EyebrowMD>
          </div>
        )}
        {!isTheatrical && (
          <div className="flex flex-col justify-end">
            <ParagraphLG className="hidden lg:block" color={headerTextColor}>
              {percentProgress ? new Intl.NumberFormat(locale).format(percentProgress) : '< 1'}%
            </ParagraphLG>
            <ParagraphSM className="block lg:hidden" color={headerTextColor}>
              {percentProgress ? new Intl.NumberFormat(locale).format(percentProgress) : '< 1'}%
            </ParagraphSM>
          </div>
        )}
      </div>
      <div className={classNames('flex flex-col gap-1', fullWidthClass)}>
        <div className="flex flex-row justify-between lg:hidden">
          <div className="flex flex-col gap-1 lg:gap-2">
            <ParagraphSM color={headerTextColor} className="block lg:hidden" weight="semibold">
              {views}
            </ParagraphSM>
          </div>
          <div className="flex flex-col gap-1 lg:gap-2">
            <ParagraphSM color={paragraphTextColor} className="block lg:hidden">
              {t('ofViewsGoal', 'of {{viewsGoal}} views', { viewsGoal })}
            </ParagraphSM>
          </div>
        </div>
        <div className="flex basis-1/2 flex-col gap-2 lg:gap-6">
          <div className={classNames('w-full rounded-full', isDarkMode ? 'bg-core-gray-800' : 'bg-core-gray-200')}>
            <div
              className="h-2.5 rounded-full bg-oxide-700"
              style={{ width: `${milestoneProgress > 100 ? '100%' : milestoneProgress ?? 0}%` }}
            />
          </div>
          <div className="flex flex-row justify-between">
            <div className="hidden flex-col gap-1 lg:flex lg:gap-2">
              <TitleLG color={headerTextColor} className="hidden text-xl lg:block xl:text-2xl" weight="semibold">
                {views}
              </TitleLG>
              <div className="flex flex-row items-center">
                <ParagraphLG color={paragraphTextColor} className="hidden text-base lg:block xl:text-lg">
                  {t('ofViewsGoal', 'of {{viewsGoal}} views', { viewsGoal })}
                </ParagraphLG>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
