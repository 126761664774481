import { FC } from 'react'
import classNames from 'classnames'
import { CaptionSM, CaptionXS, TitleLG, TitleSM } from '@/atoms/Text'
import { slugs } from '@/constants'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { useIsDarkMode } from '@/contexts/ThemeContext'
import { ProjectFundingGoalDisplayPlaceholder } from '@/organisms/ProjectFundingGoalDisplay/ProjectFundingGoalDisplayPlaceholder'
import { UseGoalValues } from '@/services/GoalService'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'

interface GoalDisplayProps extends UseGoalValues {
  fullWidth?: boolean
}

const COST_PER_TICKET = 14

export const ProjectFundingGoalDisplay: FC<GoalDisplayProps> = ({
  fullWidth = false,
  cost,
  loading,
  error,
  raisedRaw,
  costRaw,
  percentProgress,
  raised,
  backers,
  groupName,
}) => {
  const { t } = useTranslate('common')
  const isDarkMode = useIsDarkMode()
  const { slug, fundingPurpose } = useProjectMeta()
  const { locale } = useLocale()

  if (!cost || loading || error) {
    return <ProjectFundingGoalDisplayPlaceholder fullWidth={fullWidth} />
  }
  const season = groupName ?? t('season1', 'Season 1')

  const currentSeasonEpisode = `${season}`

  const headerTextColorClass = isDarkMode ? '!text-white' : '!text-gray-950'
  const paragraphTextColorClass = isDarkMode ? '!text-gray-300' : '!text-gray-600'

  const fullWidthClass = !fullWidth ? '' : 'lg:flex-row lg:gap-14'

  const isShowTicketsGifted = slug === slugs.hisOnlySon || slug === slugs.soundOfFreedom
  const numTicketsGifted = raisedRaw ? Math.round(raisedRaw / 100 / COST_PER_TICKET) : 0
  const numTicketsGoal = costRaw ? Math.round(costRaw / 100 / COST_PER_TICKET) : 0
  const percentProgressDisplay = percentProgress && percentProgress > 100 ? 100 : percentProgress

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-2">
        {isShowTicketsGifted ? (
          <>
            <CaptionSM className={classNames('uppercase', paragraphTextColorClass)}>
              {t('totalBoxOffice', 'Total Box Office')}
            </CaptionSM>
            <TitleLG weight="bold" className={classNames('capitalize', headerTextColorClass)}>
              {new Intl.NumberFormat(locale).format(numTicketsGifted)} {t('tickets', 'Tickets')}
            </TitleLG>
          </>
        ) : (
          <>
            <CaptionSM className={classNames('uppercase', paragraphTextColorClass)}>
              {t('totalRaised', 'Total Raised')}
            </CaptionSM>
            <TitleLG weight="bold" className={classNames('uppercase', headerTextColorClass)}>
              {raised}
            </TitleLG>
          </>
        )}
      </div>
      <div className={classNames('flex flex-col gap-10', fullWidthClass)}>
        <div className="flex basis-1/2 flex-col gap-4">
          <div className="flex flex-row items-center justify-between">
            <CaptionSM className={classNames('uppercase', paragraphTextColorClass)}>
              {t('progress', 'Progress')}
            </CaptionSM>
            <CaptionSM className={classNames('uppercase', paragraphTextColorClass)}>{currentSeasonEpisode}</CaptionSM>
          </div>
          <div className={classNames('w-full rounded-full', isDarkMode ? 'bg-gray-800' : 'bg-gray-200')}>
            <div className="h-4 rounded-full bg-oxide-700" style={{ width: `${percentProgressDisplay}%` }} />
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-2">
              <CaptionSM className={classNames('uppercase', paragraphTextColorClass)}>
                {t('backers', 'Backers')}
              </CaptionSM>
              <TitleSM weight="bold" className={classNames('uppercase', headerTextColorClass)}>
                {backers}
              </TitleSM>
            </div>
            <div className="flex flex-col gap-2">
              <CaptionSM className={classNames('uppercase text-right', paragraphTextColorClass)}>
                {t('goal', 'Goal')}
              </CaptionSM>
              <TitleSM weight="bold" className={classNames('uppercase', headerTextColorClass)}>
                {isShowTicketsGifted
                  ? // Note, this number is never expected to be 1. The goal will be in the thousands.
                    t('numberOfTickets', '{{numTickets}} Tickets', {
                      numTickets: new Intl.NumberFormat(locale).format(numTicketsGoal),
                    })
                  : cost}
              </TitleSM>
            </div>
          </div>
        </div>
        {fundingPurpose && (
          <div className="flex basis-1/2 flex-col gap-2">
            <TitleSM className={classNames('uppercase', headerTextColorClass)}>
              {t('whatThisMoneyGoesToward', 'What This Money Goes Toward')}
            </TitleSM>
            <CaptionXS className={classNames('uppercase mt-1', paragraphTextColorClass)}>{fundingPurpose}</CaptionXS>
          </div>
        )}
      </div>
    </div>
  )
}
