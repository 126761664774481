import React, { useState } from 'react'
import classNames from 'classnames'
import { QuestionMarkFilled } from '@/atoms/Icons/QuestionMarkFilled'
import { ParagraphXS } from '@/atoms/Typography'
import { useTranslate } from '@/utils/translate/translate-client'

interface ProjectFundingIconTooltipProps {
  isDark?: boolean
  iconSize?: number
}

export const ProjectFundingIconTooltip: React.FC<ProjectFundingIconTooltipProps> = ({
  isDark = true,
  iconSize = 16,
}) => {
  const [isShown, setShown] = useState<boolean>(false)
  const { t } = useTranslate('tickets')
  const toggleShown = () => setShown((currentShown) => !currentShown)

  return (
    <div
      onMouseOver={() => setShown(true)}
      onMouseOut={() => setShown(false)}
      onClick={toggleShown}
      className="relative py-[5px]"
    >
      <ParagraphXS
        className={classNames(
          'absolute bottom-full right-0  rounded text-sm w-[260px] py-2 px-2 text-center !normal-case drop-shadow-md transition ease-in-out',
          isDark ? 'text-gray-200 bg-core-gray-900' : 'text-gray-950 bg-white',
          {
            'scale-100': isShown,
            'scale-0': !isShown,
          },
        )}
      >
        {t(
          'twoMillionTraffickedChildrenDisclaimer',
          'Our 2 million ticket goal symbolizes our commitment to the 2 million trafficked children around the world. Actual numbers may be adjusted after auditing.',
        )}
      </ParagraphXS>
      <QuestionMarkFilled color1={'green'} color2={'white'} size={iconSize} className="h-4 cursor-help lg:h-5" />
    </div>
  )
}
