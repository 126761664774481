import { getCookie, setCookie } from 'cookies-next'
import { DateTime } from 'luxon'
import { Durations } from '@/constants/durations'
import { getItemOrFirstEntry } from '@/utils/array-utils'

export function parseTheatricalDate(dateTime: string): Date {
  try {
    const parts = dateTime.split('T')
    const parsed = DateTime.fromFormat(parts[0], 'yyyy-MM-dd', { zone: 'local' })
    if (isNaN(parsed.toMillis())) {
      // eslint-disable-next-line no-console
      console.error(new Error(`The datetime string provided is invalid: "${dateTime}"`))
      return new Date()
    }
    return parsed.toJSDate()
  } catch (err) {
    return new Date()
  }
}

export function getStartDate(date1: Date, date2: Date): Date {
  return date1.getTime() > date2.getTime() ? date1 : date2
}

export function getDayBefore(inputDate: Date) {
  const oneDay = Durations.ONE_DAY_IN_MILLISECONDS
  const previousDayTimestamp = inputDate.getTime() - oneDay
  return new Date(previousDayTimestamp)
}

export function generateDates(startDate: Date, endDate: Date, maxDaysToShow = 30): Date[] {
  const numberOfDays = DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), 'days').days
  const durations = [
    ...Array(Math.trunc(numberOfDays <= 0 || maxDaysToShow < numberOfDays ? maxDaysToShow : numberOfDays)),
  ]

  return durations.map((_, i) => {
    return DateTime.fromJSDate(startDate).plus({ day: i }).toJSDate()
  })
}

export const sanitizePhoneNumber = (phone: string | null | undefined) => {
  const normalizePhone =
    phone?.replace(/-/g, '').replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').split('ext')[0] ?? ''
  if (normalizePhone && normalizePhone.includes('+') && normalizePhone?.length === 12) {
    return `${normalizePhone}`
  } else if (normalizePhone && normalizePhone?.startsWith('1') && normalizePhone?.length === 11) {
    return `+${normalizePhone}`
  } else if (normalizePhone && normalizePhone?.length === 10) {
    return `+1${normalizePhone}`
  } else return normalizePhone
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function publishTicketIntent(payload: { theatricalSlug: string; [key: string]: any }) {
  const cookieName = `ticket_intent_${payload.theatricalSlug}`
  const cookieValue = getCookie(cookieName)
  if (cookieValue) return

  try {
    const url = `${process.env.NEXT_PUBLIC_HYDRA_REST_API_URL}/tickets/purchase_intent`

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })

    if (response.ok && response.status < 400) {
      setCookie(cookieName, 'true', { maxAge: Durations.TEN_YEARS_IN_SECONDS })
    }
  } catch (err) {
    /* do nothing */
  }
}

interface resultType {
  date: Date
  zip?: string
  lat?: number
  lng?: number
}

interface assignQueryParamsProps {
  date?: string[] | string
  zip?: string[] | string
  lat?: string[] | string
  lng?: string[] | string
}
export function assignQueryParams({ zip, date, lat, lng }: assignQueryParamsProps) {
  let result: resultType = {
    date: new Date(),
  }

  // Check if the zip is set and if it is an array or string
  if (zip) {
    result = { ...result, zip: getItemOrFirstEntry(zip) }
  }

  if (lat && lng) {
    result = { ...result, lat: parseFloat(getItemOrFirstEntry(lat)), lng: parseFloat(getItemOrFirstEntry(lng)) }
  }

  // Check if the date is set and if it is an array or string
  if (date) {
    result = { ...result, date: DateTime.fromFormat(getItemOrFirstEntry(date), 'yyyy-MM-dd').toJSDate() }
  }

  return result
}

export function generateUrlWithParams(url: string, params: { zip?: string; date?: Date }) {
  let result = url

  const formattedDate = params?.date
    ? DateTime.fromJSDate(params.date).toFormat('yyyy-MM-dd')
    : DateTime.now().toFormat('yyyy-MM-dd')

  if (params?.zip && params.zip.length > 0) {
    result = url.concat(`?zip=${params.zip}&date=${formattedDate}`)
  }

  return result
}

export function getPeakMountainTime(date?: DateTime) {
  const nowMt = date ?? DateTime.now().setZone('America/Denver')
  const eightPmMt = nowMt.set({ hour: 20, minute: 0, second: 0, millisecond: 0 })

  if (nowMt > eightPmMt) {
    const nextDay = eightPmMt.plus({ days: 1 })
    return getPeakMountainTime(nextDay)
  }

  return eightPmMt.toISO()
}

export const isAngelIntegratedCountry = (countryCode: string | undefined) => {
  if (!countryCode) return false
  return countryCode?.toUpperCase() === 'US' || countryCode?.toUpperCase() === 'CA'
}

export function isQueryCoordinates(term: string) {
  const coordinatePattern = /^-?\d{1,2}\.\d+,\s?-?\d{1,3}\.\d+$/

  return coordinatePattern.test(term)
}
