import React, { Dispatch, FC, SetStateAction } from 'react'
import { QuestionMarkFilled } from '@/atoms/Icons/QuestionMarkFilled'
import { AsDiv, ParagraphLG, ParagraphSM, TitleLG, TitleMD } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'
import { ProjectFundingIconTooltip } from '@/views/TicketCheckoutViews/ShowtimesView/components/ProjectFundingIconTooltip'

export interface ProjectFundingTheatricalTextProps {
  headerTextColor: 'white' | 'core-gray-950'
  milestoneProgress: number | null | undefined
  paragraphTextColor: 'core-gray-300' | 'core-gray-500'
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  isTheatrical: boolean
  backers: string | null | undefined
}

export const ProjectFundingTheatricalText: FC<ProjectFundingTheatricalTextProps> = React.memo(
  ({ headerTextColor, milestoneProgress, paragraphTextColor, setIsModalOpen, isTheatrical, backers }) => {
    const { t } = useTranslate('pif')
    return isTheatrical ? (
      <>
        <div className="flex flex-col justify-between gap-1 lg:ml-auto lg:gap-2">
          <ParagraphSM color={headerTextColor} className={`flex justify-start lg:hidden iPadPro105:hidden`}>
            {t('percentOfTicketsGoal', '{{percentOfGoal}}% of Goal', {
              percentOfGoal: milestoneProgress,
            })}
          </ParagraphSM>
          <TitleLG
            color={headerTextColor}
            className="hidden justify-start text-xl !font-semibold xl:text-2xl iPadPro105:flex"
          >
            {t('percentOfTicketsGoal', '{{percentOfGoal}}% of Goal', {
              percentOfGoal: milestoneProgress,
            })}
          </TitleLG>
          <ParagraphLG color={paragraphTextColor} className="hidden justify-start text-base xl:text-lg iPadPro105:flex">
            {t('2MillionFor2Million', '#2MillionFor2Million')}{' '}
            <span onClick={() => setIsModalOpen(true)} className="m-auto block cursor-help">
              <QuestionMarkFilled color1={'green'} color2={'white'} className="h-4 cursor-help lg:h-5" />
            </span>
          </ParagraphLG>
        </div>
        <div className="lg:display min-[1042px]:hidden ml-auto flex flex-col gap-1 lg:gap-2 iPadPro105:hidden">
          <TitleLG color={headerTextColor} className="hidden justify-start text-xl !font-semibold lg:flex xl:text-2xl">
            {t('percentOfTicketsGoal', '{{percentOfGoal}}% of Goal', {
              percentOfGoal: milestoneProgress,
            })}
          </TitleLG>
          <ParagraphLG
            as={AsDiv}
            color={paragraphTextColor}
            className="hidden justify-start text-base lg:flex xl:text-lg"
          >
            {t('2MillionFor2Million', '#2MillionFor2Million')}
            <ProjectFundingIconTooltip isDark={false} iconSize={20} />
          </ParagraphLG>
        </div>
        <div className="iPadPro105:display flex flex-col gap-1 lg:gap-2">
          <ParagraphSM
            as={AsDiv}
            color={paragraphTextColor}
            className="flex justify-start !font-semibold lg:hidden iPadPro105:hidden"
          >
            {t('2MillionFor2Million', '#2MillionFor2Million')}
            <span onClick={() => setIsModalOpen(true)} className="m-auto block cursor-help">
              <QuestionMarkFilled color1={'green'} color2={'white'} className="h-4 cursor-help lg:h-5" />
            </span>
          </ParagraphSM>
        </div>
      </>
    ) : (
      <div className="flex flex-col gap-1 lg:gap-2">
        <TitleLG color={headerTextColor} className="hidden justify-end !font-semibold lg:flex">
          {backers}
        </TitleLG>
        <ParagraphLG color={paragraphTextColor} className="hidden justify-end lg:flex ">
          {t('people', 'People')}
        </ParagraphLG>
        <TitleMD color={headerTextColor} className="flex justify-end !font-semibold lg:hidden">
          {backers}
        </TitleMD>
        <ParagraphSM color={paragraphTextColor} className="flex justify-end lg:hidden ">
          {t('people', 'People')}
        </ParagraphSM>
      </div>
    )
  },
)

ProjectFundingTheatricalText.displayName = 'React.memo(ProjectFundingTheatricalText)'
